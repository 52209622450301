import * as Sentry from '@sentry/browser';
Sentry.init({
    dsn: 'https://bc9fcbee7c9644f0a1f0959b58d3e0c8@sentry.io/893081',
});

// Style imports
import 'mdbootstrap/css/bootstrap.min.css';
import 'mdbootstrap/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'flag-icon-css/css/flag-icon.min.css';
import 'typeface-open-sans';

// Javascript imports
// TODO: Track https://github.com/mdbootstrap/bootstrap-material-design/issues/245
window.bsCustomFileInput = require('mdbootstrap/js/modules/vendor/bs-custom-file-input');
import 'mdbootstrap/js/jquery-3.4.1.min';
import 'mdbootstrap/js/popper.min';
import 'mdbootstrap/js/bootstrap.min';
import 'mdbootstrap/js/mdb.min';

// Cookie consent import - not important for main content
import 'cookieconsent/build/cookieconsent.min';
import 'cookieconsent/build/cookieconsent.min.css';

import '../css/default.less';

import 'html5shiv';

// Register service worker
console.log('Service worker only running in production, current env: ' + process.env.NODE_ENV);
if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
    // Use the window load event to keep the page load performant
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/dist/service-worker.js', {scope: '/'}).then((registration) => {
            console.log('SW registered: ', registration);
        }).catch((registrationError) => {
            console.log('SW registration failed: ', registrationError);
        });
    });
}
