window.cookieconsent.initialise({
    // container: document.getElementById('content'),
    palette: {
        popup: {
            background: '#eeeeee',
            text: '#212529',
        },
        button: {background: '#4285F4'},
    },
    revokable: false,
    theme: 'edgeless',
    law: {
        regionalLaw: true,
    },
    location: true,
    content: {href: '/imprint'},
});

if (module.hot) module.hot.accept();
